import { inArray } from '../../../helpers/functions'
export const advisors = {
  ART: [
    { value: '1000377', text: 'Morton, Jeffrey' },
    { value: '9900', text: 'Weichbrodt, Elissa' }
  ],
  BIB: [
    { value: '56648', text: 'Dryden, Jeff' },
    { value: '1029520', text: 'Irwin, Luke' },
    { value: '1064566', text: 'Jones, Scott' },
    { value: '1008012', text: 'Kapic, Kelly' },
    { value: '1118904', text: 'Madueme, Hans' },
    { value: '1363759', text: 'Ward, Clifton' },
    { value: '1026287', text: 'Ward, Herb' }
  ],
  BIBM: [
    { value: '56648', text: 'Dryden, Jeff' },
    { value: '1029520', text: 'Irwin, Luke' },
    { value: '1064566', text: 'Jones, Scott' },
    { value: '1008012', text: 'Kapic, Kelly' },
    { value: '1118904', text: 'Madueme, Hans' },
    { value: '1363759', text: 'Ward, Clifton' },
    { value: '1026287', text: 'Ward, Herb' }
  ],
  BIO: [
    { value: '1411798', text: 'Martinez, Lynell' },
    { value: '1000299', text: 'Morris, Tim' },
    { value: '1067829', text: 'Nelson, Richard' }
  ],
  BIOP: [
    { value: '1411798', text: 'Martinez, Lynell' },
    { value: '1000299', text: 'Morris, Tim' },
    { value: '1067829', text: 'Nelson, Richard' }
  ],
  BUS: [
    { value: '1423966', text: 'Babin, Paul' },
    { value: '1193321', text: 'Matos-Wood, Arwen' },
    { value: '41880', text: 'Quatro, Scott' },
    { value: '9929', text: 'Slavovsky, Mark' }
  ],
  CHE: [
    { value: '1257303', text: 'Hoobler, Preston' },
    { value: '1068624', text: 'Zuidema, Dan' }
  ],
  CDV: [
    { value: '101337', text: 'Corbett, Steve' },
    { value: '1000287', text: 'Mask, Russell' },
    { value: '1000203', text: 'Fikkert, Brian' }
  ],
  COS: [
    { value: '1117271', text: 'Humphries, Jeffrey' }
  ],
  ECO: [
    { value: '1181011', text: 'Rush, John' },
    { value: '40579', text: 'Wescher, Lance' }
  ],
  EDST: [
    { value: '740', text: 'Bagby, Amy' }
  ],
  EEEC: [
    { value: '598', text: 'Beckman, Jack' },
    { value: '371', text: 'Donaldson, Sarah' },
    { value: '9427', text: 'Tinholt, Elaine' },
    { value: '740', text: 'Bagby, Amy' }
  ],
  ENG: [
    { value: '1119611', text: 'Barham, Robert Erle' },
    { value: '1030206', text: 'Hess, Heather' },
    { value: '42660', text: 'Huffines, Sarah' },
    { value: '493', text: 'Macallister, Gwen' },
    { value: '47752', text: 'Tate, Bill' }
  ],
  FRE: [
    { value: '1426844', text: 'White, Bryant' }
  ],
  HIS: [
    { value: '1009037', text: 'Follett, Richard' },
    { value: '1000248', text: 'Green, Jay' },
    { value: '1057509', text: 'Jackson, Alicia' },
    { value: '1000301', text: 'Morton, Paul' }
  ],
  'HIS-PL': [
    { value: '1009037', text: 'Follett, Richard' }
  ],
  IDS: [
    { value: '1000248', text: 'Green, Jay' }
  ],
  INTS: [
    { value: '2254', text: 'Horne, Cale' }
  ],
  MAT: [
    { value: '1188424', text: 'Cyr, Chris' }
  ],
  MUS: [
    { value: '649', text: 'Finch, Scott' },
    { value: '1094056', text: 'Kim, Lok' },
    { value: '1053361', text: 'Kreuze, Brandon' },
    { value: '1120566', text: 'Tahere, David' }
  ],
  PHI: [
    { value: '1000186', text: 'Davis, Bill' },
    { value: '101942', text: 'Wingard, John' }
  ],
  PHY: [
    { value: '1000171', text: 'Broussard, Phill' }
  ],
  POLS: [
    { value: '2254', text: 'Horne, Cale' }
  ],
  NSEN: [
    { value: '1113475', text: 'Stern, Curt' }
  ],
  PSY: [
    { value: '1414414', text: 'Wilson, Rodney' },
    { value: '1164520', text: 'Yue, Carole' }
  ],
  SOC: [
    { value: '1116724', text: 'Robinson, Chris' },
    { value: '1000230', text: 'Vos, Matt' }
  ],
  SOCF: [
    { value: '1116724', text: 'Robinson, Chris' },
    { value: '1000230', text: 'Vos, Matt' }
  ],
  SPA: [
    { value: '1371619', text: 'Forteza, Deborah' },
    { value: '1165945', text: 'Stambaugh, Brianne' }
  ],
  SPMG: [
    { value: '1305182', text: 'Kourmoulis, Michaela' }
  ],
  THEATRE: [
    { value: '2878', text: 'Slavovsky, Claire' }
  ]
}
export const concentrations = {
  ART: [
    { value: '2DAR', text: '2-D Arts', majors: [] },
    { value: '3DAR', text: '3-D Arts', majors: [] },
    { value: 'ARTH', text: 'Art History', majors: [] },
    { value: 'GRAD', text: 'Graphic Design', majors: [] },
    { value: 'PHOT', text: 'Photography', majors: [] }
  ],
  BIB: [
    { value: 'MIS', text: 'Missions', majors: [] }
  ],
  BIO: [
    { value: 'BIOM', text: 'Biomedical', majors: [] },
    { value: 'BIOE', text: 'Environmental', majors: [] },
    { value: 'BIOH', text: 'Health Professions', majors: [] }
  ],
  BUS: [
    { value: 'ACC', text: 'Accounting', majors: [] },
    { value: 'FIN', text: 'Finance', majors: [] },
    { value: 'MKT', text: 'Marketing', majors: [] },
    { value: 'SPMG', text: 'Sport Management', majors: [] }
  ],
  CHE: [
    { value: 'BIOC', text: 'Biochemistry', majors: [] }
  ],
  ENG: [
    { value: 'WRIT', text: 'Writing', majors: [] }
  ],
  HIS: [
    { value: 'HISA', text: 'Art History', majors: [] },
    { value: 'POLS', text: 'Political Science', majors: [] }
  ],
  IDS: [
    { value: 'ACC', text: 'Accounting', majors: [] },
    { value: 'ART', text: 'Art', majors: [] },
    { value: 'BIB', text: 'Biblical & Theological Studies', majors: [] },
    { value: 'BILA', text: 'Biblical Languages', majors: [] },
    { value: 'BIO', text: 'Biology', majors: [] },
    { value: 'BUS', text: 'Business', majors: [] },
    { value: 'CHE', text: 'Chemistry', majors: [] },
    { value: 'PEC', text: 'Coaching', majors: [] },
    { value: 'CDV', text: 'Community Development', majors: [] },
    { value: 'COS', text: 'Computer Science', majors: [] },
    { value: 'ECO', text: 'Economics', majors: [] },
    { value: 'EDU', text: 'Education', majors: [] },
    { value: 'ENG', text: 'English', majors: [] },
    { value: 'FRE', text: 'French', majors: [] },
    { value: 'HIS', text: 'History', majors: [] },
    { value: 'MKT', text: 'Marketing', majors: [] },
    { value: 'MAT', text: 'Mathematics', majors: [] },
    { value: 'MIS', text: 'Missions', majors: [] },
    { value: 'MUS', text: 'Music', majors: [] },
    { value: 'PHI', text: 'Philosophy', majors: [] },
    { value: 'PHY', text: 'Physics', majors: [] },
    { value: 'POLS', text: 'Political Science', majors: [] },
    { value: 'PSY', text: 'Psychology', majors: [] },
    { value: 'SOC', text: 'Sociology', majors: [] },
    { value: 'SPA', text: 'Spanish', majors: [] },
    { value: 'SPMG', text: 'Sport Management', majors: [] },
    { value: 'THET', text: 'Theatre', majors: [] },
    { value: 'YTHM', text: 'Youth Ministry', majors: [] }
  ],
  MUS: [
    { value: 'MUCM', text: 'Church Music', majors: [] },
    { value: 'MUCS', text: 'Creative Studies', majors: [] },
    { value: 'MUSG', text: 'General Music', majors: [] },
    { value: 'MUSI', text: 'Instrumental', majors: [] },
    { value: 'MEDU', text: 'Music Education', majors: [] },
    { value: 'MUSO', text: 'Organ Performance', majors: [] },
    { value: 'MUPP', text: 'Piano Pedagogy', majors: [] },
    { value: 'MUSP', text: 'Piano Performance', majors: [] },
    { value: 'MUTC', text: 'Theory/Composition', majors: [] },
    { value: 'MVOP', text: 'Vocal Performance', majors: [] }
  ],
  SOC: [
    { value: 'SFSW', text: 'Family Studies and Social Work', majors: [] }
  ]
}
export const IDSPrimaryConcentrations = [
  { value: 'ACC', text: 'Accounting', majors: [] },
  { value: 'ART', text: 'Art', majors: [] },
  { value: 'BIB', text: 'Biblical & Theological Studies', majors: [] },
  { value: 'BIO', text: 'Biology', majors: [] },
  { value: 'BUS', text: 'Business', majors: [] },
  { value: 'CHE', text: 'Chemistry', majors: [] },
  { value: 'CDV', text: 'Community Development', majors: [] },
  { value: 'ECO', text: 'Economics', majors: [] },
  { value: 'ENG', text: 'English', majors: [] },
  { value: 'FRE', text: 'French', majors: [] },
  { value: 'HIS', text: 'History', majors: [] },
  { value: 'MKT', text: 'Marketing', majors: [] },
  { value: 'MAT', text: 'Mathematics', majors: [] },
  { value: 'MUS', text: 'Music', majors: [] },
  { value: 'PHI', text: 'Philosophy', majors: [] },
  { value: 'PHY', text: 'Physics', majors: [] },
  { value: 'POLS', text: 'Political Science', majors: [] },
  { value: 'PSY', text: 'Psychology', majors: [] },
  { value: 'SOC', text: 'Sociology', majors: [] },
  { value: 'SPA', text: 'Spanish', majors: [] },
  { value: 'SPMG', text: 'Sport Management', majors: [] },
  { value: 'THET', text: 'Theatre', majors: [] },
  { value: 'YTHM', text: 'Youth Ministry', majors: [] }
]
export const certAdvisors = {
  ARAD: [
    { value: '1120566', text: 'Tahere, David' }
  ],
  ENTR: [
    { value: '41880', text: 'Quatro, Scott' },
    { value: '1000238', text: 'Goodman, Leda' }
  ],
  ENSS: [
    { value: '1426843', text: 'Laing, Joelle' }
  ],
  JOSO: [
    { value: '42660', text: 'Huffines, Sarah' }
  ],
  MEDE: [
    { value: '1000186', text: 'Davis, Bill' }
  ],
  NEUR: [
    { value: '1067829', text: 'Nelson, Richard' },
    { value: '1164520', text: 'Yue, Carole' }
  ],
  TESL: [
    { value: '1165945', text: 'Stambaugh, Brianne' }
  ]
}
export const certificates = [
  { value: 'ARAD', text: 'Arts Administration' },
  { value: 'ENTR', text: 'Entrepreneurship' },
  { value: 'ENSS', text: 'Environmental Stewardship and sustainability' },
  { value: 'JOSO', text: 'Journalism and Society' },
  { value: 'MEDE', text: 'Medical Ethics Consultation' },
  { value: 'NEUR', text: 'Neuroscience' },
  { value: 'TESL', text: 'Teaching English to Speakers of Other Languages' }
]

export function convertData (original) {
  const output = {
    program: [],
    minor: [],
    certificate: [],
    p12: false,
    EDSTContentFields: []
  }
  const origMajrAdvr = original.advisor.filter(({ code }) => code === 'MAJR').map(({ pidm }) => pidm)
  for (const major of original.major) {
    const prog = { major, advisor: '', conc: [], primary: '' }
    // Look for an advisor that is in the list of available ones for this major
    if (major in advisors) {
      for (const { value } of advisors[major]) {
        if (inArray(value, origMajrAdvr)) {
          prog.advisor = value
        }
      }
    }
    // Look at the concentrations for this major and see if any of their concentrations match
    if (major in concentrations && original.concentration.length > 0) {
      if (major === 'IDS') {
        // We are considering the first concentration as the primary
        if (IDSPrimaryConcentrations.find(({ value }) => value === original.concentration[0]) == null) {
          prog.primary = original.concentration[0]
        } else if (IDSPrimaryConcentrations.find(({ value }) => value === original.concentration[1]) == null) {
          // Just in case the first one is not a primary, but this shouldn't ever happen
          prog.primary = original.concentration[1]
        }
        for (let i = 1; i < original.concentration.length; i++) {
          prog.conc.push(original.concentration[i])
        }
      } else {
        for (const { value } of concentrations[major]) {
          if (inArray(value, original.concentration)) {
            prog.conc.push(value)
          }
        }
      }
    }
    output.program.push(prog)
  }
  output.minor = [ ...original.minor ] || []
  const origCertAdv = original.advisor.filter(({ code }) => code === 'CERT').map(({ pidm }) => pidm)
  for (const cert of original.certificate) {
    const temp = { cert, advisor: '' }
    if (cert in certAdvisors) {
      for (const { value } of certAdvisors[cert]) {
        if (inArray(value, origCertAdv)) {
          temp.advisor = value
        }
      }
      if (temp.advisor === '' && certAdvisors[cert].length === 1) {
        temp.advisor = certAdvisors[cert][0].value
      }
    }
    output.certificates.push(temp)
  }
  return output
}
